import React from "react";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  HowECLVideo,
  HowECLWorksNav,
  Layout,
  SEO,
} from "components";
import { graphql } from "gatsby";

const AnalyzePage = ({ data, location }) => {
  const title = "Unified ELN, LIMS, Data Analysis & Visualization";
  const description =
    "Leverage the exhaustive suite of tools to analyze, and visualize your data";
  return (
    <FullBackground gradient>
      <HowECLWorksNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <HowECLVideo data={data.section} />
      </Layout>
    </FullBackground>
  );
};

export default AnalyzePage;

export const query = graphql`
  query HowECLWorksSectionAnalyze {
    section: howEclWorksJson(section: { eq: "analyze" }) {
      title
      videoURL
      paragraph
      btnText
      nextRoute
      controls {
        controls
      }
      bookmarks {
        text
        time
      }
    }
  }
`;
